.homeContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(270deg, #153567 0%, #061d4b 52.8%, #00143d 100%);
  /* padding-top: 6%; */
  /* border: 2px solid darkblue; */
  padding-bottom: 50px;
  /* margin-bottom: 30vh; */
  /* border: 5px solid red; */
}

.homeSubContent {
  /* width: 50%; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* font-family: Georgia, 'Times New Roman', Times, serif; */
  font-weight: 500;
  /* border: 2px solid black; */
  /* margin: 10px; */
}

.products {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.productCard {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  margin: 20px;
  /* border: 2px solid mediumorchid; */
}

.cardcontainer {
  /* width: 350px; */
  /* height: 40px; */
}

/* this card is inspired form this - https://georgefrancis.dev/ */
.homecard {
  padding: 1rem;
  background-color: #fff;
  width: 260px;
  border-radius: 10px;
  /* box-shadow: 10px 10px #323232; */
  /* border: 3px solid #c71616; */
}
.homecard:hover {
  transform: translate(-5px, -5px);
  transition: all 0.8s cubic-bezier(0.1, 0.78, 0.61, 1);
  /* box-shadow: 13px 13px 0 #000; */
}

.title_text {
  font-family: CircularStd-Bold, Arial, sans-serif;
  font-weight: 700;
  color: #00143d;
  font-size: 1.125rem;
  line-height: 1.2;
  letter-spacing: -0.015625rem;
}
.homecard:hover .title_text {
  color: #1976d2 !important;
}

.homeContentDiv {
  margin: 10px;
  /* width: 50%; */
}

.homeContent {
  /* font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif; */
  text-align: center;
  /* font-size: 30px; */
  /* font-weight: 800; */
  color: #fff;
  /* text-transform: uppercase; */
  line-height: 1.2;
  font-size: 2.25rem;
  letter-spacing: 0;
  margin-bottom: 1rem !important;
  font-family: CircularStd-Bold, Arial, sans-serif !important;
  font-weight: 600 !important;
  font-style: normal !important;
}
/* h1 em {
  font-style: normal;
  font-weight: 600;
} */

.subContent {
  margin-top: 5px;
  font-size: 15px;
  color: #efff;
  word-spacing: 1px;
  font-weight: normal;
  letter-spacing: 2px;
  text-align: center;
  /* text-transform: uppercase;  */
  /* font-family:"Raleway", sans-serif; */
  font-weight: 500;
  /* display: grid;s */
  /* grid-template-columns: 1fr max-content 1fr;
  grid-template-rows: 27px 0;
  grid-gap: 20px; */
  align-items: center;
  /* padding-top: 15px;
  padding-bottom: 15px */
  padding: 15px;
}
.hometitle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.homeSVG {
  width: 50px;
  height: 50px;
}

.title_text {
  font-family: Roboto, "Helvetica Neue", Arial, sans-serif;
  font-family: CircularStd-Bold, Arial, sans-serif;
  margin-left: 0.5rem;
  margin-top: 10px;
  margin-bottom: 10px;
  color: #374151;
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 700;
}

.percent {
  margin-left: 0.5rem;
  color: #02972f;
  font-weight: 600;
  display: flex;
}

.data {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

/* .data p {
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: #1f2937;
  font-size: 2.25rem;
  line-height: 2.5rem;
  font-weight: 700;
  text-align: left;
} */

.data .range {
  position: relative;
  background-color: #e5e7eb;
  width: 100%;
  height: 0.5rem;
  border-radius: 0.25rem;
}

.data .range .fill {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #10b981;
  width: 76%;
  height: 100%;
  border-radius: 0.25rem;
}

.fill {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  color: #70757a;
  font-size: larger;
  line-height: 1.5rem;
  margin-top: -17px;
}

@media (min-width: 1800px) {
  .homeContentDiv {
    margin: 10px;
    width: 800px;
    /* border: 2px solid yellow; */
  }
  .homeContainer {
    padding-top: 4%;
  }
  .homeSubContent {
    width: 800px;
    /* border: 2px solid yellow; */
  }
  .homecard {
    width: 260px;
  }
}
@media (min-width: 991px) {
  .homeContentDiv {
    margin: 10px;
    width: 800px;
  }
  .homeContainer {
    padding-top: 4%;
  }
  .homeSubContent {
    width: 800px;
  }
  .homecard {
    width: 260px;
  }
}

@media (max-width: 768px) {
  .homeContentDiv {
    margin: 10px;
    width: 800px;
  }
  .homeContainer {
    padding-top: 4%;
  }
  .homeSubContent {
    width: 800px;
    /* border: 2px solid white; */
  }
  .homecard {
    width: 260px;
  }
}

@media (max-width: 767px) {
  .homeContentDiv {
    margin: 10px;
    width: 600px;
  }
  .homeContainer {
    padding-top: 4%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .homeSubContent {
    width: 600px;
  }
  .homecard {
    width: 260px;
  }
}

@media (max-width: 615px) {
  .homeContentDiv {
    margin: 0px 50px;
    width: 100%;
  }
  .homeContainer {
    padding-top: 8%;
  }
  .homeSubContent {
    width: 100%;
    margin: 0px 15px;
    letter-spacing: 1px;
  }
  .subContent {
    /* color: #70757a; */
  }
  .homecard {
    width: 260px;
    margin-bottom: 10px;
  }
  .homeContent {
    font-size: 30px;
    font-weight: 800;
    word-spacing: 2px;
    letter-spacing: 1.5px;
    margin-bottom: 10px;
  }
}
