.bankSearchMobile {
  /* margin-left: 10px; */
  /* margin-right: 20px; */
  margin-bottom: 23px;

  max-width: 30.25rem;
}

.MuiAutocomplete-root {
  border-radius: 5px !important;
  border: 1px solid seagreen !important;
}
.searchLogo {
  /* border: 1px solid blueviolet; */
  margin-left: 10px;
}

@media (max-width: 768px) {
  .bankSearchMobile {
    /* margin-left: 15px; */
    margin-right: 0;

    max-width: 28rem;
    /* min-height: 2rem; */
    /* height: 2rem; */
  }
}
@media (max-width: 991px) {
  .bankSearchMobile {
    /* margin-left: 15px; */
    margin-right: 15px;
    /* min-height: 2rem; */
    /* height: 2rem; */
  }
}
