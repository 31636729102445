.bankListWrapper2 {
  /* border: 2px solid blue; */
  /* position: sticky; */
  /* top: 60px; */
  /* z-index: 100; */
  /* overflow:hidden; */
  display: flex;
  /* align-items: center; */
  /* padding: 0 1rem; */
  margin-bottom: 50px;
  justify-content: center;
}

.bankListContainer2 {
  display: flex;
  /* align-items: center; */
  justify-content: center;
  /* font-family: var(--font); */
  font-family: "Poppins", sans-serif;
  padding: 0 1.5rem;
  /* margin-top: 10px; */
  /* border: 2px solid blue; */
  /* position: sticky; */
  /* top: 100px; */
}

.bankListContainer2 {
  width: 100%; /* Adjust width as needed */
  /* margin: 50px auto; */
  /* border: 3px solid #2a0dd2; */
  /* border-bottom: 1px solid #c9c8cf; */
  border-radius: 1px;
  /* overflow-x: scroll; */
}

/* .filterContainer{
  display: flex;
  position: sticky;
  top: 60;
} */
.tableWraper2 {
  width: 70%;
  /* border-top: 1px solid #c9c8cf;
  border-bottom: 1px solid #c9c8cf; */
  /* box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); */
  /* overflow: auto; */

  /* border: 2px solid #180e11; */
  /* display: block; */
  /* overflow: auto; */
  /* top: 0; */
  /* height:  85vh; */
  /* padding: 10px; */
}
.tableBorder {
  width: 100%;
  /* box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23); */
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border-radius: 5px;
  /* border: 2px solid #d7f207; */
}
/* Table styling */
.tableWraper2 table {
  margin-top: 0px;
  margin-bottom: 0px;
  background-color: #ffffff;
  /* margin-bottom: 16px; */
  /* margin: 1em 0; */
  /* overflow: scroll; */
  /* display:block; */
  overflow: auto;

  /* border: 5px solid rebeccapurple; */
  /* overflow-y: hidden; */
  /* overflow-x: auto; */
  /* overflow-x: scroll; */
  /* overflow: auto; */

  width: 100%;
  /* border-collapse: collapse; */
  border-radius: 4px;
  font-family: "Poppins", sans-serif;
  /* box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.3); */
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  /* border: 2px solid #dadce0; */
  /* box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23); */
}

.responsiveTable2 th,
td {
  /* padding: 12px; */
  text-align: left;
  border-bottom: 1px solid #ddd;
  font-family: "Poppins", sans-serif;
  /* border: 1px solid black; */
}

/* .responsiveTable2{
  height: 80vh;
} */

.responsiveTable2 th {
  background-color: #e0e4e9;
  font-weight: 700;
  /* text-transform: uppercase; */
  /* font-size: 0.75rem; */
  /* letter-spacing: 0.1em; */
  font-family: Inter, sans-serif;
  padding: 18px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.responsiveTable2 td {
  color: #2d3748;
  font-family: Inter, sans-serif;
}

.responsiveTable2 tbody td {
  /* padding-left: 15px; */
  white-space: nowrap;
  /* overflow: scroll; */
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 21px;
  /* color: #1a60da; */
  /* border: 1px solid black; */
}

.responsiveTable2 tbody tr:hover {
  background-color: #f4f6f7;
}

/* Highlight headers on hover */
.responsiveTable2 th:hover {
  background-color: #e5e5e5;
}
.responsiveTable2 thead {
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  /* border: 2px solid black; */
  vertical-align: top;
  font-size: 16px;
  border-radius: 4px;
  color: #4a5568;
  cursor: pointer;
  /* position: sticky;
  top: 0; */
  background-color: #e0e4e9;
  z-index: 1;
}

.responsiveTable2 tbody {
  color: #2d3748;
}

thead th {
  justify-content: center;
  align-items: center;
  /* background-color: red; */
  /* padding: 18px; */
  /* padding-left: 10px; */
  /* padding-right: 10px; */
  /* border: 1px solid black; */
}

.bankDetails2 span {
  color: #00b386;
  color: #1a73e8;
  font-size: 14px;
}

.twTableDomain:hover {
  color: #195afe;
}

.appFaviconSM {
  width: 44px;
  height: 44px;
}

/* .appFavicon {
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  margin-right: 5px;
  background: #fff;
  box-shadow: 4px 5px 16px rgba(64, 68, 77, 0.12);
  border-radius: 8px;
} */
.appFavicon img {
  object-fit: contain;
  min-width: 0;
  width: 22px;
  height: 22px;
  border-radius: 4px;
}
img {
  overflow-clip-margin: content-box;
  overflow: clip;
}

.twTableDomain {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.arrow {
  width: 15px;
  height: 15px;
  margin-left: 5px;
}

.sortDiv {
  /* display: inline; */
  display: flex;
  flex-direction: row;
  align-items: center;
}

.filterButton {
  display: none;
  width: 87px;
  height: 2.4rem;
  /* margin: 10px;
  padding: 3px 8px;
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  padding-right: 2px;
  border: 1px solid #ddd;
  border-radius: 3px; */
  cursor: pointer;
  background: #ffffff;
  border: 1px solid #ccc;
  transition: border-color 0.3s ease;
  /* font-size: 14px;
  line-height: 1.25;
  font-weight: 600;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); */
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.filterParentContainer {
  /* background-color: red; */
  margin: 0;
  padding: 0;
  /* color:red; */
}
.filterButtonContainer {
  display: flex;
  margin-left: 15px;
}

/* .filterHeading img {
  margin-bottom: 3px;
} */
.filterHeading {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .updateMessageContainer{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
} */
.top_controls__date {
  display: flex;
  /* width: 85%; */
  /* flex-direction: row; */
  align-items: center;
  justify-content: center;
  font-size: 0.95rem;
  line-height: 16px;
  color: #8995a1;
  background-color: #f5f7fa;
  padding: 0;
  margin: 0;
}
.updateMsg0 {
  /* border: 3px solid salmon; */
}
.updateMsg {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  width: 100%;
  /* border: 3px solid salmon; */
  margin-bottom: 3px;

  padding: auto;
}
.bankHeading2 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* border: 2px solid black; */
}
.features {
  margin-top: 30px;
}
.featuresList {
  margin: 20px 0;
}
.featuresList ul li {
  margin: 10px 0;
  /* font-size: 14px; */
  font-size: 0.95rem;
  line-height: 17px;
  color: #8995a1;
  /* line-height: 1.3rem; */
}
/* .interestRate {
  padding-left: 14px;
} */

.showColumnCheckbox {
  /* border: 3px solid salmon; */
  display: flex;
  justify-content: left;
  align-items: center;
  margin: 10px;
  margin-right: -10px;
  margin-left: 10px;
  /* margin-left: 310px; */
  /* overflow: hidden; */
  text-overflow: ellipsis;
  /* border: 2px solid saddlebrown; */
}
.checkboxWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin: 10px; */
  margin-right: 25px;
  /* border: 2px solid black; */
}
.showColumnCheckbox input {
  /* margin-right: 50px; */
}
.showColumnCheckbox label {
  margin-left: 8px;
  font-size: 16px;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  /* text-overflow: ellipsis; */
}

.showColumnInput {
  height: 15px;
  width: 15px;
}

.emptyDivContainer {
  display: flex;
  justify-content: left;
}
.emptyDiv {
  /* width: 300px; */
  /* background-color: red;
  border: 2px solid blueviolet; */
}

.Tenor {
  /* display: block; */
  /* border: 2px solid gold; */
  margin-top: 8px;
  padding-bottom: 8px;

  font-family: "Google Sans", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.1px;
  line-height: 20px;
  color: #202124;
}

.subTenor {
  margin-top: -8px;
  /* margin-bottom: -12px; */
  /* border: 1px solid darkblue; */
}
.subTenor1 {
  margin-top: -5px;
  height: 17px;
}

.daysToYear {
  font-family: Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.3px;
  line-height: 16px;
  color: #70757a;
  margin-left: 3px;
  margin-top: 0;
}

.information {
  cursor: pointer;
  color: #70757a;
  flex: 0 0 auto;
  forced-color-adjust: auto;
  margin-left: 4px;
  align-items: center;
  fill: currentColor;
}
.infoTooltip {
  background-color: #4a5568;
  width: 200px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.seniorRate {
  display: flex;
  flex-direction: column;
  /* margin-top: 10px; */
  padding: 0;
  margin: 0;
  /* margin-top: 2px; */
  padding-top: 5px;
  /* border: 1px solid goldenrod; */
}

.seniorRate1 {
  display: flex;
  /* flex-direction: column; */
  /* margin-top: 10px; */
  padding: 0;
  margin: 0;
  /* padding-top: 10px; */
  /* border: 2px solid black; */
}
.RateAndTenor {
  margin-bottom: 8px;
  font-family: "Google Sans", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.1px;
  line-height: 20px;
  color: #202124;
}

.RateAndTenor1 {
  margin-bottom: 5px;
  margin-top: 3px;
  font-family: "Google Sans", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.1px;
  line-height: 20px;
  color: #202124;
}

.bankTypeRow {
  font-family: "Google Sans", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.1px;
  line-height: 20px;
  color: #202124;
}
.seniorInfo {
  color: #70757a;
  flex: 0 0 auto;
  forced-color-adjust: auto;
  margin-left: 4px;
  align-items: center;
}

.banknameToottip {
  background-color: #4a5568;
}

.highlighterDiv {
  align-items: center;
  display: flex;
  margin-top: 8px;
  /* margin-bottom: 8px; */
  /* margin-left: 25px; */
  /* margin-bottom: 5px; */
  margin-top: -10px;
  margin-bottom: -2px;
}
.highlighter {
  background-clip: padding-box;
  border: 1px solid transparent;
  /* overflow: hidden; */
  /* text-overflow: ellipsis; */
  white-space: nowrap;
  background-color: #e6f4ea;
  color: #0d652d;
  font: 400 10px / 19px Roboto, Arial, sans-serif;
  letter-spacing: 0.3px;
  height: 20px;
  padding: 0 5px;
  white-space: nowrap;
}

.filler {
  /* border: 1px solid blueviolet; */
  font-family: Roboto, "Helvetica Neue", Arial, sans-serif;
  /* font-size: 13px; */
  font-weight: 500;
  letter-spacing: 0.3px;
  line-height: 16px;
  height: 15px;
  /* color: #70757a; */
}
.tooltip {
  background-color: black;
  color: white;
}

.slider {
  width: 70%;
}

.FDcontainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.card {
  width: 100%;
  /* max-width: 590px; */
  height: 44px;
  /* height: 80px; */
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: left;
  /* background-color: red; */
  /* backdrop-filter: blur(10px);
  transition: 0.5s ease-in-out; */
  margin-left: -13px;
  /* border: 2px solid saddlebrown; */
}

.textContent:hover p {
  color: #195afe;
}

.img {
  width: 44px;
  height: 44px;
  margin-left: 10px;
  border-radius: 4px;
  background: linear-gradient(#ffff, #ffff);
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #e6e5ee;
  /* background-color: #fff; */
}

.textBox {
  width: calc(100% - 90px);
  margin-left: 10px;
  font-family: "Google Sans", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.1px;
  line-height: 20px;
  color: #202124;
}

.textContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* margin: 0;
  padding: 0; */
  /* border: 1px solid brown; */
}

.span {
  font-size: 10px;
}

.h1 {
  font-size: 17px;
  /* font-weight: bold; */
  color: #2d3748;

  /* font-family: "Poppins" sans-serif; */
  /* margin: 0; */
}

.p {
  font-size: 14px;
  font-weight: lighter;
}

.bankSearch {
  min-width: 270px;

  max-width: 480px;
  /* border: 2px solid darkblue; */
  margin-bottom: -10px;
  /* margin-left: 10px; */
}

.searchWrapper {
  display: flex;
}

.searchContainer {
  display: flex;
  align-items: center;
  width: 100%;
  /* border: 1px solid red; */
  /* max-width: 500px; */
  justify-content: center;
}
.dropdown {
  margin-bottom: 10px;
}

@media (min-width: 1800px) {
  /* .tableWraper {
    width: 100%;
    border: 2px solid red;
  } */

  .bankListWrapper2 {
    margin: auto;
    padding: auto;
    /* width: 65%; */
    max-width: 1734px;
  }
  .tableBorder {
    display: block;
    overflow: auto;
  }
  .rank {
    margin-left: 6px;
    /* padding: 0; */
    /* border: 1px solid gold; */
  }
  .fdRank {
    margin-left: 10px;
    /* padding: 0; */
    /* border: 1px solid gold; */
  }
  .bankName {
    /* border: 1px solid rgb(125, 15, 25); */
    margin-left: -20px;
    /* margin-right: -5px; */
  }
  .card {
    margin-left: -30px;
    margin-right: -65px;
    /* border: 1px solid silver; */
  }
  .h1 {
    /* border: 1px solid rgb(235, 21, 195); */
    /* margin-right: 10px !important; */
    /* padding-right: 25px !important; */
  }

  .interestRate {
    /* border: 1px solid rgb(23, 194, 246); */
    margin-left: -15px;
  }
  .seniorRate1 {
    /* border: 1px solid rgb(10, 141, 228); */
    margin-left: -15px;
    margin-bottom: 2px;
    /* margin-right: 20px; */
  }
  .interestRate1 {
    /* border: 1px solid palevioletred; */
    margin-right: -40px;
    margin-left: 0px;
  }
  .interestRate2 {
    /* border: 1px solid palevioletred; */
    margin-right: -30px;
    margin-left: 0px;
  }

  .seniorRate {
    /* border: 1px solid palevioletred; */
    margin-right: 35px;
    /* margin-left: 20px; */
  }

  .interestPayout {
    /* border: 1px solid rgb(125, 15, 25); */
    /* margin-left: 3px; */
  }

  .tenor {
    /* border: 1px solid rgb(125, 15, 25); */
    margin-left: 20px;
    margin-right: 10px;
  }
  .Tenor {
    /* border: 1px solid rgb(125, 15, 25); */
    margin-top: 8px;
    /* margin-bottom: 10px; */
    margin-left: 15px;
    margin-right: -20px;
    flex-direction: column;
    /* padding-left: -20px; */
  }
  .bankType {
    /* border: 1px solid rgb(125, 15, 25); */
    margin-left: 15px;
    margin-right: -10px;
  }
  .bankTypeRow {
    /* border: 1px solid rgb(136, 195, 26); */
    margin-left: 15px;
    margin-right: -10px;
  }
}

@media (min-width: 1230px) {
  .tableBorder {
    display: block;
    overflow: auto;
  }
  .card {
    /* margin-left: -30px; */
    margin-right: -35px;
    /* border: 1px solid silver; */
  }
  .h1 {
    /* border: 1px solid rgb(235, 21, 195); */
    padding-right: 20px !important;
  }
  .bankSearch {
    min-width: 300px;
    /* max-width: 370px; */
    /* margin-bottom: 10px; */
    /* margin-left: 10px; */
    margin-right: 10px;
  }
}
@media (max-width: 1230px) {
  .tableWraper2 table {
    /* width: 100%; */
    /* margin-right: 20px;     */
    /* display: block; */
    /* overflow: auto; */

    /* box-sizing: border-box; */
    /* border: 2px solid black; */
  }
  .tableBorder {
    display: block;
    overflow: auto;
  }
  .responsiveTable2 thead {
    /* border: 2px solid black; */
  }
  .bankListContainer2 {
    /* margin: 0px 15px; */
    /* margin-right: 20px;     */

    padding: 0 3.5rem;
    /* border: 2px solid black; */
    /* align-items: center; */
  }
  /* .checkboxWrapper {
    display: flex;
    margin-right: 15px;
  } */
  .bankSearch {
    margin-left: 65vh;
    min-width: 370px;
    max-width: 370px;
    /* margin-bottom: 10px; */
    /* margin-left: 10px; */
  }
}

@media (max-width: 991px) {
  .bankListContainer2 {
    /* margin: 0px 25px; */
    padding: 0 1.5rem;
    /* border: 2px solid rgb(255, 0, 0); */
  }
  .tableBorder {
    display: block;
    overflow: auto;
  }
  .tableWraper2 {
    width: 100%;
    margin: 0px 15px;
    /* border: 2px solid rgb(45, 2, 156); */
  }
  .responsiveTable2 {
    /* border: 2px solid black; */
  }
  .filterButton {
    display: flex;
  }
  .tableWraper2 table {
    /* display: block; */
    /* box-sizing: border-box; */
  }
  .updateMsg {
    margin-left: 0;
    padding-top: 5px;
    width: 100%;
  }
  .updateMsg0 {
    display: none;
  }
  .seniorFlagCheck {
    font-size: 13px !important;
    font-weight: 700 !important;
  }

  .showColumnCheckbox {
    margin-left: 0;
  }
  .showColumnCheckbox label {
    margin-left: 5px;
    font-size: 12px;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    /* text-overflow: ellipsis; */
  }
  .bankListContainer2 {
    /* margin: 0px 15px; */
    /* border: 2px solid black; */
  }
  .bankSearch {
    min-width: 380px;
    max-width: 370px;
    /* margin-bottom: 10px; */
    margin-left: 10px;
  }
  .filterButtonContainer {
    display: flex;
    margin-left: 25px;
  }
}

@media (max-width: 768px) {
  .tableWraper2 {
    width: 100%;
  }
  .bankListContainer2 {
    /* margin: 0px 15px; */
  }
  .tableWraper2 table {
    /* display: block; */

    font-size: 14px;
    /* font-size: 0.75rem; */

    /* width: 100%; */
    /* box-sizing: border-box; */
  }
  .h1 {
    font-size: 14px !important;
    /* border: 2px solid rgb(235, 21, 195); */
    margin-right: 25px !important;
  }
  .tableWraper2 table tr {
    font-size: 14px;

    width: 100%;
    /* font-size: 0.75rem; */
  }

  .appFavicon img {
    object-fit: contain;
    min-width: 0;
    width: 18px;
    height: 18px;
    border-radius: 3px;
  }
  .responsiveTable2 thead tr th {
    padding: 14px;
    /* padding-right: 0; */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .responsiveTable2 td {
    /* padding: 14px; */
  }
  .checkboxWrapper {
    margin-right: 25px;
  }
  .interestRate {
    /* border: 1px solid rgb(23, 194, 246); */
    margin-left: -6px;
  }
  .seniorRate1 {
    /* border: 1px solid rgb(10, 141, 228); */
    margin-left: -12px;
    /* margin-right: 20px; */
  }
  .card {
    margin-left: -20px;
    margin-right: -35px;
    font-size: 14px;
    /* height: 34px; */
    /* border: 2px solid saddlebrown; */
  }
  /* .bankSearch {
    min-width: 30vh;
    max-width: 25vh;
    margin-left: 10px;
    margin-bottom: 10px;
  } */
  .bankSearch {
    min-width: 320px;

    max-width: 370px;
    /* margin-bottom: 10px; */
    /* margin-left: 10px; */
  }
}

@media (max-width: 568px) {
  .bankListContainer2 {
    /* margin: 0px 25px; */
    padding: 0 1rem;
    /* border: 2px solid rgb(255, 0, 0); */
  }
  .filterButtonContainer {
    display: flex;
    margin-left: 15px;
  }
  .responsiveTable2 td {
    padding: 12px;
  }
  td,
  th {
    /* border: 1px solid black; */
  }
  .filterButton {
    margin: 2px;
    padding: 5px;
  }
  .rank {
    margin-left: -6px;
    padding: 0;
  }
  .fdRank {
    margin-left: -10px;
    padding: 0;
  }
  .card {
    margin-left: -20px;
    margin-right: -35px;
    font-size: 14px;
    /* height: 34px; */
    /* border: 2px solid saddlebrown; */
  }
  .h1 {
    font-size: 14px !important;
    /* border: 2px solid rgb(235, 21, 195); */
    margin-right: 25px !important;
  }
  .p {
    font-size: 12px;
    /* border: 2px solid saddlebrown; */
  }
  .highlighterDiv {
    margin-right: 20px;
  }
  .img {
    height: 25px;
    width: 25px;
  }

  .interestRate {
    /* border: 1px solid rgb(23, 194, 246); */
    margin-left: -6px;
  }
  .seniorRate1 {
    /* border: 1px solid rgb(10, 141, 228); */
    margin-left: -12px;
    margin-bottom: 2px;
    /* margin-right: 20px; */
  }
  .interestPayout {
    /* border: 1px solid rgb(125, 15, 25); */
    /* margin-left: 3px; */
  }
  .bankName {
    /* border: 1px solid rgb(125, 15, 25); */
  }
  .tenor {
    /* border: 1px solid rgb(125, 15, 25); */
    margin-left: -10px;
  }
  .Tenor {
    /* border: 1px solid rgb(125, 15, 25); */
    margin-top: 8px;
    /* margin-bottom: 10px; */
    margin-left: -15px;
    flex-direction: column;
    /* padding-left: -20px; */
  }
  .subTenor1 {
    /* border: 1px solid rgb(125, 15, 25); */
    height: 17px;
  }
  .bankType {
    /* border: 1px solid rgb(125, 15, 25); */
    margin-left: -5px;
  }
  .bankTypeRow {
    /* border: 1px solid rgb(136, 195, 26); */
    margin-left: -10px;
  }
  .card {
    /* border: 1px solid rgb(125, 15, 25); */
  }
  .daysToYear {
    /* border: 2px solid rgb(10, 141, 228); */
    margin-left: 0px;
  }
  .checkboxWrapper {
    margin: 0;
    margin-right: 0px;
    /* margin-bottom: 10px; */
  }
  .showColumnCheckbox input {
    margin: 0;
    margin-left: 10px;
  }
  .showColumnCheckbox {
    margin-left: -9px;
  }
  .highlighterDiv {
    display: flex;
    align-items: center;
    margin-top: -10px;
    margin-bottom: -2px;
    /* margin-left: 25px; */
    /* margin-bottom: 5px; */
  }
  .highlighter {
    background-clip: padding-box;
    border: 1px solid transparent;
    /* overflow: hidden; */
    /* text-overflow: ellipsis; */
    white-space: nowrap;
    background-color: #e6f4ea;
    color: #0d652d;
    font: 400 8px / 15px Roboto, Arial, sans-serif;
    letter-spacing: 0.2px;
    height: 16px;
    padding: 0 3px;
    white-space: nowrap;
  }
  .textBox {
    padding-top: 10px;
    /* border: 1px solid goldenrod; */
    display: flex;
    flex-direction: column;
  }
  .textContent {
    /* border: 1px solid goldenrod; */
  }

  .showColumnInput {
    height: 12px;
    width: 12px;
  }

  .bankSearch {
    min-width: 270px;

    max-width: 370px;
    /* margin-bottom: 10px; */
    /* margin-left: 10px; */
  }
}
