h1 {
  font-family: sohne, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 2.2rem;
  font-weight: 700;
  color: #333;
  line-height: 1.2;
  margin-bottom: 1rem;
  /* margin-top: 1rem; */
}

h2 {
  font-family: sohne, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 2rem;
  font-weight: 600;
  color: #333;
  line-height: 1.3;
  margin-bottom: 0.75rem;
}

h3 {
  font-family: sohne, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1.75rem;
  font-weight: 500;
  color: #333;
  line-height: 1.4;
  margin-bottom: 0.75rem;
}

p {
  font-family: source-serif-pro, Georgia, Cambria, "Times New Roman", Times,
    serif;
  font-size: 1rem;
  color: #555;

  font-size: 18px;
  margin-bottom: 1rem;
}

.commentSubText {
  font-family: Inter, ui-sans-serif, system-ui, -apple-system, system-ui,
    Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif,
    Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-weight: 500;
  font-size: 16px;
}

.wp-block-list {
  list-style-type: disc;
  padding-left: 1.5rem;
  margin-bottom: 1.5rem;
}

.wp-block-list li {
  font-family: "Arial", sans-serif;
  font-size: 1rem;
  color: #555;
  margin-bottom: 0.5rem;
  line-height: 1.7;
}

.wp-block-list {
  list-style-type: decimal;
  padding-left: 1.5rem;
  margin-bottom: 1.5rem;
}

.wp-block-list li {
  font-family: "Arial", sans-serif;
  font-size: 1rem;
  color: #555;
  margin-bottom: 0.5rem;
  line-height: 1.7;
}

blockquote {
  font-style: italic;
  color: #777;
  border-left: 4px solid #0073e6;
  padding-left: 1rem;
  margin: 1.5rem 0;
  background-color: #f9f9f9;
}
/* Basic styling for <code> tags */

code,
mark,
.wp-block-preformatted {
  display: block;
  background-color: #333333;
  color: #f4f4f4;
  font-family: Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace;
  font-size: 0.9em;
  line-height: 1.5;
  padding: 1em;
  margin: 0.5em 0;
  border-radius: 5px;
  overflow-x: auto;
  white-space: pre-wrap;
  word-wrap: break-word;
}

/* For inline code */
p code mark {
  display: inline;
  padding: 0.2em 0.4em;
  margin: 0;
  font-size: 0.85em;
  background-color: #f0f0f0;
  border-radius: 3px;
  font-family: "Inter", Helvetica, arial, sans-serif;
}

@media (max-width: 768px) {
  h1 {
    font-size: 2rem;
    margin-bottom: 0.75rem;
  }

  h2 {
    font-size: 1.75rem;
    margin-bottom: 0.6rem;
  }

  h3 {
    font-size: 1.5rem;
    margin-bottom: 0.6rem;
  }
}

/* Mobile Devices (Portrait) */
@media (max-width: 480px) {
  h1 {
    font-size: 1.75rem;
    margin-bottom: 0.5rem;
  }

  h2 {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
  }

  h3 {
    font-size: 1.25rem;
    margin-bottom: 0.5rem;
  }
}

/* Very Small Devices (e.g., Small Phones) */
@media (max-width: 320px) {
  h1 {
    font-size: 1.5rem;
    margin-bottom: 0.4rem;
  }

  h2 {
    font-size: 1.25rem;
    margin-bottom: 0.4rem;
  }

  h3 {
    font-size: 1rem;
    margin-bottom: 0.4rem;
  }
}
